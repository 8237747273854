import React, { useEffect, useMemo, useState } from "react";
import { api } from "../../../../services/api";
import { getUser } from "../../../../services/utils";
import { RbtRequest } from "../RequestRbt.types";
import { BACKEND_URL } from "../../../../env";

export const RBTRequestContext = React.createContext<{ rbtRequests: Array<RbtRequest>; refreshRequests: () => {} }>({
  rbtRequests: [],
  refreshRequests: () => ({})
});

export function RbtRequestProvider({ children }) {
  const user = getUser();
  const [requests, setRequests] = useState<Array<RbtRequest>>([]);

  const fetchRequests = useMemo(() => async () => {
    const results = (await api.getRbtRequests()).map(request => {
      return {
        ...request,
        rbtCandidates: request.rbtCandidates.map(candidate => {
          return ({
            ...candidate,
            resumeUrl: candidate.resumeUrl ? `${BACKEND_URL}/s3-file/${encodeURIComponent(candidate.resumeUrl)}` : null
          })
        })
      }
    })

    setRequests(results)
  }, []);

  useEffect(() => {
    if (user?.id && user?.type === 'P') {
      fetchRequests();
    }
  }, [user?.id]);

  const values = useMemo(() => ({
    rbtRequests: requests,
    refreshRequests: fetchRequests,
  }), [requests, fetchRequests]);

  return <RBTRequestContext.Provider value={values}>{children}</RBTRequestContext.Provider>;
}

export function useRbtRequests() {
  const context = React.useContext<{ rbtRequests: Array<RbtRequest>; refreshRequests: () => {} }>(RBTRequestContext);
  if (context === undefined) {
    throw new Error("useRbtRequests must be used within a RBTRequestProvider");
  }
  return context;
}
