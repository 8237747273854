import { useEffect, useRef, useState } from "react";
import ReactGA from 'react-ga4';
import { Button } from "../../../components/Button";
import { QrCodeUpload } from "./components/QrCodeUpload";
import { ManualUpload } from "./components/ManualUpload";
import { QrCodeSuccess } from "./components/QrCodeSuccess";
import { fileUpload } from "../../../services/file-upload";
import { getToken, getUser } from "../../../services/utils";
import { api } from "../../../services/api";
import { ShowToast } from "../../../services/toast";

export function FamilyRegistrationStep2({
  onNext,
  isFromLogin,
}: {
  onNext: () => void;
  isFromLogin: boolean;
}) {
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [frontSide, setFrontSide] = useState<File | null>(null);
  const [backSide, setBackSide] = useState<File | null>(null);
  const [isPrimaryInsuranceUploaded, setIsPrimaryInsuranceUploaded] =
    useState(false);
  const [isSecondaryInsuranceSelected, setIsSecondaryInsuranceSelected] =
    useState(false);
  const [uploadType, setUploadType] = useState<"qr" | "manual">("qr");
  const user = getUser();
  const token = getToken();
  const intervalRef = useRef<any>(null);

  const handleUploadType = () => {
    setUploadType(uploadType === "qr" ? "manual" : "qr");
  };

  const handleFileChange = (file, side) => {
    setSaving(false);
    if (side === "front") setFrontSide(file);
    if (side === "back") setBackSide(file);
  };
  const handleSubmit = async () => {
    // upload the files
    if (isPrimaryInsuranceUploaded && !isSecondaryInsuranceSelected) {
      if (process.env.REACT_APP_ENV === "prod") {
        ReactGA.event("insurance-upload-completed-step2")
      }
      onNext();
      return;
    }
    try {
      if (uploadType === "manual" && frontSide && backSide) {
        // upload the files
        setSaving(true);
        const frontSideResponse = await fileUpload(
          frontSide,
          user.id,
          "INSURANCE"
        );
        const backSideResponse = await fileUpload(
          backSide,
          user.id,
          "INSURANCE"
        );

        const payload = {
          frontImg: frontSideResponse.url,
          backImg: backSideResponse.url,
          isPrimary: !isPrimaryInsuranceUploaded,
        };
        const response = await api.saveInsurance(user.id, payload);
        setSaving(false);
        if ([200, 201].includes(response.status)) {
          setSuccess(true);
          ShowToast({
            message: "Insurance Uploaded!",
            type: "success",
          });
          if (!isPrimaryInsuranceUploaded) {
            setIsPrimaryInsuranceUploaded(true);
            onNext();
          } else {
            if (process.env.REACT_APP_ENV === "prod") {
              ReactGA.event("insurance-upload-completed-step2")
            }
            onNext();
          }
        }
      } else {
        if (!isPrimaryInsuranceUploaded && process.env.REACT_APP_ENV === "prod") {
          ReactGA.event("insurance-upload-completed-step2")
        }
        setSaving(false);
        ShowToast({
          message: "Insurance Uploaded!",
          type: "success",
        });
        onNext();
      }
    } catch (error) {
      setSaving(false);
      ShowToast({
        message: "Something went wrong while uploading the files",
        type: "error",
      });
    }
  };

  const handleSecondaryInsurance = () => {
    // show view to upload second insurance
    setIsSecondaryInsuranceSelected(true);
    setFrontSide(null);
    setBackSide(null);
    setUploadType("qr");
    setSuccess(false);
  };

  useEffect(() => {
    // if upload type is qr then check if the user has uploaded the images
    // call api at some interval to check if the user has uploaded the images
    if (uploadType === "qr") {
      intervalRef.current = setInterval(async () => {
        try {
          const response = await api.getInsurance(user.id);
          if (
            [200, 201].includes(response.status) &&
            response.data &&
            Array.isArray(response.data)
          ) {
            if (isSecondaryInsuranceSelected) {
              const data = response.data[1];
              if (data && data?.frontImg && data?.backImg) {
                setSuccess(true);
                clearInterval(intervalRef.current);
                intervalRef.current = null;
              }
            } else {
              const data = response.data[0];
              if (data && data?.frontImg && data?.backImg) {
                setSuccess(true);
                setIsPrimaryInsuranceUploaded(true);
                clearInterval(intervalRef.current);
                intervalRef.current = null;
              }
            }
          }
        } catch (e) { }
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [uploadType, user.id, isSecondaryInsuranceSelected]);

  return (
    <>
      <div className="bg-white p-8 rounded-[16px]">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl sm:text-3xl font-bold">Insurance Details</h1>
          <div className="text-sm text-black/60">2/3</div>
        </div>
        <form className="mt-4 flex flex-col gap-[22px] text-sm lg:text-base">
          {uploadType === "qr" ? (
            success ? (
              <QrCodeSuccess />
            ) : (
              <QrCodeUpload
                userId={user.id}
                token={token}
                isPrimary={!isPrimaryInsuranceUploaded}
              />
            )
          ) : (
            <ManualUpload
              onChange={handleFileChange}
              frontSide={frontSide}
              backSide={backSide}
            />
          )}
          {!success && (
            <Button
              variant="link"
              className="!text-primary !font-medium !text-base"
              onClick={handleUploadType}
              type="button"
            >
              {uploadType === "manual"
                ? "Use QR code to upload photos"
                : "Upload photos manually"}
            </Button>
          )}
          {isPrimaryInsuranceUploaded && !isSecondaryInsuranceSelected && (
            <Button
              variant="link"
              className="!text-primary !font-medium !text-base"
              onClick={handleSecondaryInsurance}
              type="button"
            >
              Do you have secondary insurance?
            </Button>
          )}
          {isSecondaryInsuranceSelected && (
            <Button
              variant="link"
              className="!text-primary !font-medium !text-base"
              onClick={() => {
                setIsSecondaryInsuranceSelected(false);
                setSuccess(true);
                clearInterval(intervalRef.current);
              }}
              type="button"
            >
              Don't have secondary insurance?
            </Button>
          )}

          <div className="mt-2">
            <Button
              type="submit"
              variant="primary"
              className="!rounded-full w-full"
              onClick={handleSubmit}
              disabled={
                saving ||
                (uploadType === "manual"
                  ? !frontSide || !backSide
                  : !success) ||
                (isSecondaryInsuranceSelected &&
                  (uploadType === "manual"
                    ? !frontSide || !backSide
                    : !success))
              }
              loading={saving}
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
