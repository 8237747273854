import { useRef, useState } from "react";
import ReactGA from 'react-ga4';
import { Button } from "../../../components/Button";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as PinIcon } from "../../../assets/icons/pin.svg";
import { ReactComponent as XIcon } from "../../../assets/icons/x.svg";
import { fileUpload } from "../../../services/file-upload";
import { getToken, getUser, setUser } from "../../../services/utils";
import { api } from "../../../services/api";
import { ShowToast } from "../../../services/toast";
import { FileUploader } from "react-drag-drop-files";

export function FamilyRegistrationStep3({
  onNext,
  isFromLogin,
}: {
  onNext: () => void;
  isFromLogin: boolean;
}) {
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [cdeFile, setCdeFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<any>({});
  const [cdeExists, setCdeExists] = useState<Boolean | undefined>(undefined);

  const user = getUser();
  const token = getToken();
  const intervalRef = useRef<any>(null);


  const handleFileChange = (file) => {
    setSaving(false);
    setCdeFile(file);
  };
  const handleSubmit = async () => {
    if (cdeExists === undefined) {
      return;
    }

    try {
      if (cdeExists && cdeFile) {
        // upload the files
        setSaving(true);
        const uploadResponse = await fileUpload(
          cdeFile,
          user.id,
          "CDE"
        );
        const payload = {
          cdeUrl: uploadResponse.url,
        };
        const response = await api.saveCDE(user.id, payload);
        setSaving(false);
        if ([200, 201].includes(response.status)) {
          if (process.env.REACT_APP_ENV === "prod") {
            ReactGA.event("diagnostic-evaluation-completed-step3")
          }
          setUser({ ...user, cdeUrl: uploadResponse.url });
          setSuccess(true);
          ShowToast({
            message: "DE Uploaded!",
            type: "success",
          });
          onNext();
        }
      }
    } catch (error) {
      setSaving(false);
      ShowToast({
        message: "Something went wrong while uploading the files",
        type: "error",
      });
    }

    if (!cdeExists) {
      if (process.env.REACT_APP_ENV === "prod") {
        ReactGA.event("diagnostic-evaluation-completed-step3")
      }
      onNext();
    }

  };

  return (
    <>
      <div className="bg-white p-8 rounded-[16px] text-xs sm:text-sm md:text-base">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-bold">Diagnostic Information</h1>
          <div className="text-sm text-black/60">3/3</div>
        </div>
        <p className="mt-4 font-bold">
          Do you have a copy of the Diagnostic Evaluation (DE) for the individual looking to receive services?
        </p>

        <p className="mt-6">
          <u>Note</u>: Per insurance requirements, a DE must be completed by a medical professional (pediatrician, psychiatrist, psychologist, etc.) and cannot come from a school counselor.
          It must include Autism Spectrum Disorder (ASD) as the formal diagnosis.
        </p>

        <div className="grid grid-cols-2 gap-3 items-center w-full mt-8">
          <Button
            variant={(cdeExists === true || cdeExists === undefined) ? "primary" : "gray"}
            className="px-4 py-2 !rounded-full justify-center items-center gap-2.5 flex"
            onClick={() => {
              if (cdeExists !== true) {
                setCdeExists(true)
              } else {
                if (cdeExists === true) {
                  setCdeExists(undefined);
                }
              }
            }}>
            <CheckIcon className="[&_path]:stroke-white" />
            Yes
          </Button>
          <Button
            variant={(cdeExists === false || cdeExists === undefined) ? "primary" : "gray"}
            className="px-4 py-2 !rounded-full justify-center items-center gap-2.5 flex"
            onClick={() => {
              if (cdeExists === false) {
                setCdeExists(undefined);
              } else {
                setCdeExists(false)
              }

            }}
          >
            <XIcon className="[&_path]:stroke-white" />
            No
          </Button>
        </div>

        {cdeExists === true && <>
          <div className="text-black/80 text-sm mt-10 mb-1">Please upload a copy of the DE</div>
          <FileUploader handleChange={handleFileChange} name="file" disabled={saving}>
            <div className="cursor-pointer p-4 h-[114px] bg-[#E8F3F2] w-full rounded-xl border border-primary justify-center items-center gap-1 flex">
              <PinIcon className="w-5 h-5" />
              <div className="text-primary text-base font-normal font-['Outfit'] leading-normal">
                {cdeFile
                  ? `File "${cdeFile?.name}" added`
                  : "Click to add or drag and drop"}
              </div>
            </div>
          </FileUploader>
          {errors.cdeFile && (
            <div className="text-red-500 text-xs font-medium">
              {errors.cdeFile}
            </div>
          )}

        </>}




        {cdeExists === false ?
          <div className="text-center">
            <p className="mt-8 font-bold">
              Please note that you will need to obtain the DE before beginning treatment with any care provider.
            </p>
            <p className="mt-3">
              You may upload the DE at any time by editing your profile.
            </p>
          </div>
          :
          <p className="mt-12"></p>
        }

        <div className="mt-10">
          <Button
            type="submit"
            variant="primary"
            className="!rounded-full w-full"
            onClick={handleSubmit}
            disabled={
              saving || cdeExists === undefined || (cdeExists === true && !cdeFile)
            }
            loading={saving}
          >
            Connect With a Care Provider
          </Button>
        </div>
      </div>
    </>
  );
}
